import React, { useEffect } from 'react';

export default function ShippingPolicy() {

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page when component mounts
  }, []);




  return (
    <div className='shipping_policy policy'>
      <div className="container py-5">
        <h2><strong>Shipping Policy</strong></h2>

        <p>Welcome to Nutted Protein!</p>

        <p>This Shipping Policy outlines the rules and regulations for the shipment of products purchased through Nutted Protein's Website, located at https://nuttedprotein.com/</p>

        <p>By making a purchase on this website, you agree to adhere to the terms and conditions outlined in this Shipping Policy. If you do not agree with any part of this policy, please refrain from making a purchase.</p>

        <h3><strong>Shipping Rates and shipping Time</strong></h3>

        <p>We are pleased to offer standard shipping at no additional cost to our customers. The shipping fee will be clearly mentioned during the payment process.</p>

        <p>For orders totaling 1000 rupees or more, we provide free shipping.</p>

        <p>Our standard shipping duration is between 24 to 72 hours from the time your order is processed. Please note that shipping times may vary depending on your location and any unforeseen circumstances such as holidays or adverse weather conditions.</p>

        <h3><strong>Order Processing</strong></h3>

        <p>Once your order is placed and payment is confirmed, we will begin processing your order. You will receive a confirmation email with your order details.</p>

        <p>If you have any questions regarding the shipping of your order, please don't hesitate to contact our customer service team at +91 63824 46862.</p>

        <h3><strong>Shipping Address</strong></h3>

        <p>It is your responsibility to provide accurate and complete shipping information during the checkout process. We cannot be held liable for any delays or non-shipping due to incorrect address information provided by the customer.</p>

        <h3><strong>International Shipping</strong></h3>

        <p>At this time, we only offer shipping within India. We do not provide international shipping services.</p>

        <h3><strong>Lost or Damaged Packages</strong></h3>

        <p>In the event that your package is lost or damaged during transit, please contact us immediately so that we can assist you in resolving the issue.</p>

        <h3><strong>Changes to This Policy</strong></h3>

        <p>We reserve the right to update or change this Shipping Policy at any time without prior notice. Changes will be effective immediately upon posting on this website. We encourage you to review this Shipping Policy periodically for any updates or changes.</p>

        <p>If you have any questions or concerns about our Shipping Policy, please contact us at +91 63824 46862.</p>

      </div>
    </div>
  );
}
