import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Link } from 'react-router-dom';

export default function Banner() {

  // Owl Carousel Settings
  const options = {
    loop: true,
    center: true,
    items: 3,
    margin: 0,
    autoplay: true,
    dots: false,
    autoplayTimeout: 4000,
    smartSpeed: 450,
    nav: false,
    responsive: {
      0: {
        items: 1
      },
      500: {
        items: 1
      },
      1000: {
        items: 1
      }
    }
  };

  return (
    <div>
      <OwlCarousel className="owl-carousel owl-theme" {...options}>
        <div className='homepage_banner banner-1'>
          <div className="container">
        <div className='content' data-aos="fade-up">
          <h2>Treat Yourself or Delight Loved Ones with a Perfectly Wrapped Gift of Health and Taste!</h2>
          <p>Order Now and Elevate Your Snacking Experience!</p>
          <Link to="/products" className="gold_btn text-decoration-none">Shop Now</Link>
        </div>
        </div>
        <p className='banner_bottom'>Succulent Dates 🍇 | 🍒 Juicy Raisins 🍒 | 🍑 Delicate Apricots 🍑 | 🥥 Creamy Cashews 🥥 | 🌰 Crunchy Almonds</p>

        </div>


        <div className='homepage_banner banner-2'>
  <div className="container">
    <div className='content' data-aos="fade-left">
      <h2>Savor Every Bite: Premium Nuts for Exceptional Snacking Moments!</h2>
      <p>Elevate your taste experience with our handpicked selection!</p>
      <Link to="/products" className="gold_btn text-decoration-none">Shop Now</Link>
    </div>
  </div>
  <p className='banner_bottom'>Succulent Dates 🍇 | 🍒 Juicy Raisins 🍒 | 🍑 Delicate Apricots 🍑 | 🥥 Creamy Cashews 🥥 | 🌰 Crunchy Almonds</p>

</div>


<div className='homepage_banner banner-3'>
  <div className="container">
    <div className='content' data-aos="fade-left">
      <h2>Discover Nature's Bounty: Freshness, Flavor, and Health in Every Nut!</h2>
      <p>Join the journey to healthier snacking habits!</p>
      <Link to="/products" className="gold_btn text-decoration-none">Shop Now</Link>
    </div>
  </div>
  <p className='banner_bottom'>Succulent Dates 🍇 | 🍒 Juicy Raisins 🍒 | 🍑 Delicate Apricots 🍑 | 🥥 Creamy Cashews 🥥 | 🌰 Crunchy Almonds</p>

</div>



      </OwlCarousel>
    </div>
  );
}
