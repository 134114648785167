import React, { useState } from "react";
import axios from "axios";


export default function Enquiry({setenquiry}) {


    const [name, setName] = useState("");
    const [number, setNumber] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [errors, setErrors] = useState({});
   
  
    const validateForm = () => {
      let errors = {};
      let isValid = true;
  
      if (!name.trim()) {
        errors.name = "Name is required";
        isValid = false;
      }
  
      if (!number.trim()) {
        errors.number = "Contact Number is required";
        isValid = false;
      }
  
      if (!email.trim()) {
        errors.email = "Email is required";
        isValid = false;
      }
  
  
      setErrors(errors);
      return isValid;
    };
  
    const handleNameChange = (e) => {
      setName(e.target.value);
      setErrors((prevErrors) => ({ ...prevErrors, name: "" }));
    };
  
    const handleNumberChange = (e) => {
    const inputValue = e.target.value;
    if (/^\d{0,10}$/.test(inputValue)) {
      setNumber(inputValue);
      setErrors((prevErrors) => ({ ...prevErrors, number: "" }));
    }
  };
  
    const handleEmailChange = (e) => {
      setEmail(e.target.value);
      setErrors((prevErrors) => ({ ...prevErrors, email: "" }));
    };
  
    const handleMessageChange = (e) => {
      setMessage(e.target.value);
      setErrors((prevErrors) => ({ ...prevErrors, message: "" }));
    };
  
    const SubmitEnquiry = (e) => {
      e.preventDefault();
  
      if (!validateForm()) {
        return;
      }
  
      const data = {
        Name: name,
        Number: number,
        Email: email,
        Message: message,
      };
  
      axios
        .post(
          "https://sheet.best/api/sheets/c68faad6-8899-415a-b8d8-6bb1652d3600",
          data
        )
        .then((res) => {
          console.log(res);
          setenquiry(true)
          setName("");
          setMessage("");
          setNumber("");
          setEmail("");
        });

axios.get("https://sheet.best/api/sheets/c68faad6-8899-415a-b8d8-6bb1652d3600").then((res)=>{
  console.log(res,"response")
})

    };

  return (
    <form data-aos="fade-right" className="enquiry" onSubmit={(e) => SubmitEnquiry(e)}>
    <input
     
      type="text"
      className={errors.name ? "error" : ""}
      value={name}
      onChange={handleNameChange}
      placeholder="Your Good Name"
    />
    {errors.name && <div className="error-text">{errors.name}</div>}
    <input
      type="tel"
      className={errors.number ? "error" : ""}
      value={number}
      onChange={handleNumberChange}
      placeholder="Your Contact Number"
    />
    {errors.number && (
      <div className="error-text">{errors.number}</div>
    )}
    <input
      type="email"
      className={errors.email ? "error" : ""}
      value={email}
      onChange={handleEmailChange}
      placeholder="Your Mail ID"
    />
    {errors.email && <div className="error-text">{errors.email}</div>}
    <textarea
      className={errors.message ? "error" : ""}
      value={message}
      onChange={handleMessageChange}
      placeholder="Type Message..."
    ></textarea>
 
    <button type="submit" className="gold_btn">
      Send Message
    </button>
  </form>
  )
}
