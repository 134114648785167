import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';

export default function Footer() {

  const [year,setYear]=useState()

 

  useEffect(()=>{
    var date=new Date();
    console.log(date.getFullYear())
    setYear(date.getFullYear())
  },[])

  return (
    <footer>
      <div className="container">


        <div className="logo_details">
      <img src={require('./Assets/Logo.webp')}/>
      <p>Nutted Protein offers premium-quality dry fruits and nuts at affordable prices, making wholesome nutrition accessible to all.</p>
      </div>


      <div className="split">

      <div className="social_icons">
      <h6>Connect with Us</h6>
        <ul>
          <li><a target='_blank' href='https://www.instagram.com/nuttedprotein/'><i class="fa-brands fa-instagram"></i></a> </li>
          <li><a target='_blank' href='https://www.facebook.com/profile.php?id=61553627980893&mibextid=LQQJ4d'><i class="fa-brands fa-facebook-f"></i></a> </li>
          {/* <li><a href=''><i class="fa-brands fa-x-twitter"></i></a> </li> */}
          <li><a target='_blank' href='https://wa.me/916382446862'><i class="fa-brands fa-whatsapp"></i></a> </li>

        </ul>
      </div>

      <div className="contact_details">
      <h6>Contact Us</h6>
        <ul>
          <li><a href='tel:+916382446862'><i class="fa-solid fa-mobile-screen"></i>+91 63824 46862</a> </li>
          <li><a href='mailto:nuttedprotein@gmail.com'><i class="fa-regular fa-envelope"></i>nuttedprotein@gmail.com</a> </li>
      

        </ul>
      </div>


      </div>
      </div>
      <div className="terms_condition container">
        <Link to='/termsandconditions'>Terms and Conditions</Link>
        <Link to='/privacypolicy'>Privacy Policy</Link>
        <Link to='/shippingpolicy'>Shipping Policy</Link>
        <Link to='/refundpolicy'>Refund Policy</Link>

        
      </div>
      <p className="copyrights">
      Copyright @ {year} Nutted Protein. | All Rights Reserved.
      </p>
    </footer>
  )
}
