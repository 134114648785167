import React, { useEffect, useState } from "react";
import Banner from "../components/Banner";
import Products from "./Products";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import axios from "axios";
import Enquiry from "./Enquiry";
import { Link } from "react-router-dom";


export default function Home({ setnav, addCart, cartItems }) {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page when component mounts
    setnav("home");
  }, []);

  const options = {
    loop: true,
    center: true,
    items: 3,
    margin: 0,
    autoplay: true,
    dots: true,
    autoplayTimeout: 10000,
    smartSpeed: 450,
    nav: false,
    responsive: {
      0: {
        items: 1,
      },
      500: {
        items: 1,
      },
      1000: {
        items: 1,
      },
    },
  };

  const [enquiryDone, setEnquiryDone] = useState(false);

  return (
 
    <div className="home">
      <Banner />

      <div className="our_products section ">
        <div className="container">
          <h2 className="heading white center">Our Products</h2>
          <p className="text-white text-center col-lg-10 m-auto">
            Elevate your snacking game with our premium nuts and dry fruits,
            sourced for quality and taste. Each bite is a fusion of nutrition
            and flavor, offering a guilt-free indulgence. Experience the
            goodness of nature with every pack from Nutted Protein!
          </p>
          <Products addCart={addCart} cartItems={cartItems} isHomepage={true} />
          <Link to="/products" className="gold_btn">
            View More
          </Link>
        </div>
      </div>

      <div className="about section overflow-hidden">
        <div className="container">
          <div className="content" data-aos="fade-right">
            <h2 className="heading">What is Nutted Protein?</h2>
            <p>
              At <span>Nutted Protein</span>, our mission transcends mere
              business goals – it's about nourishing lives and fostering
              wellness within communities. Since our inception in December 2023,
              we've been dedicated to providing the best dry fruits in Chennai
              at prices that everyone can afford. We believe that access to
              nutritious foods shouldn't be a luxury but a fundamental right for
              all. With a commitment to sourcing the best ingredients and
              ensuring stringent quality control measures, we strive to deliver
              products that not only tantalize the taste buds but also fuel the
              body with essential nutrients. By offering affordability without
              compromising on quality, we aim to make wholesome nutrition
              accessible to everyone, including those with limited resources. At
              Nutted Protein, we're not just selling products; we're sowing
              seeds of well-being and making a positive impact, one nutritious
              bite at a time.
            </p>
            <Link to="/about" className="gold_btn text-decoration-none">Shop Now</Link>

          </div>

          <div className="image" data-aos="fade-left">
            <img
              alt="Best Dry Fruits in Chennai"
              src={require("../Assets/cocunut.webp")}
            />
          </div>
        </div>
      </div>

      <div className="why_choose section">
        <div className="container">
          <h1 className="heading white center">Why Choose Nutted Protein?</h1>

          <div className="cards">
            <div className="_card" data-aos="fade-up" data-aos-delay="100">
              <h5>
                <img
                  alt="Premium Quality Dry Fruits"
                  src={require("../Assets/i1.webp")}
                />
                Premium Quality
              </h5>
              <p>
                Nutted Protein prides itself on sourcing only the finest quality
                dry fruits and nuts, ensuring every product meets rigorous
                standards of freshness and taste.
              </p>
            </div>

            <div className="_card" data-aos="fade-up" data-aos-delay="200">
              <h5>
                <img
                  alt="Dry Fruits in Low Price"
                  src={require("../Assets/i2.webp")}
                />
                Affordable Prices
              </h5>
              <p>
                We believe that everyone deserves access to nutritious foods,
                which is why Nutted Protein offers competitive pricing without
                compromising on quality, making healthy eating accessible to all
                budgets.
              </p>
            </div>

            <div className="_card" data-aos="fade-up" data-aos-delay="300">
              <h5>
                <img
                  alt="Fresh Dry Fruits"
                  src={require("../Assets/i3.webp")}
                />
                Freshness Guaranteed
              </h5>
              <p>
                Our products are carefully packaged to preserve freshness,
                locking in the natural flavors and nutrients of each dry fruit
                and nut, so you can enjoy them at their peak.
              </p>
            </div>

            <div className="_card" data-aos="fade-up" data-aos-delay="400">
              <h5>
                <img
                  alt="Best Dry Fruits Online"
                  src={require("../Assets/i4.webp")}
                />
                Customer Satisfaction
              </h5>
              <p>
                At Nutted Protein, customer satisfaction is our top priority. We
                strive to provide excellent service, prompt delivery, and
                responsive customer support to ensure a seamless shopping
                experience for our valued customers.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="testimonial section">
        <div className="container">
          <h2 className="heading center">What Our Customers Says</h2>

          <OwlCarousel
            data-aos="flip-up"
            className="owl-carousel owl-theme"
            {...options}
          >
            <div className="_card col-lg-7 m-auto">
              <p className="text-center">
                Nutted Protein truly lives up to its name! I've tried a variety
                of their products, and I must say, all of them are of
                exceptional quality. From almonds to raisins, each item is fresh
                and delicious. Highly recommended!
                <br />
                <br />
                <span>- &nbsp; Godhai Lakshmi</span>
              </p>
            </div>

            <div className="_card col-lg-7 m-auto">
              <p className="text-center">
                I recently tried Nutted Protein's walnut and salt pistachios,
                and I was blown away by the taste! Not only do they have an
                amazing flavor profile, but the price is unbeatable. Definitely
                my new go-to snack!
                <br />
                <br />
                <span>- &nbsp; Kamalesh</span>
              </p>
            </div>

            <div className="_card col-lg-7 m-auto">
              <p className="text-center">
                I've been a loyal customer of Nutted Protein for months now, and
                I have to say, they never disappoint. Their products are the
                best in terms of quality, and their delivery service is spot on.
                Always receive my orders right on time!
                <br />
                <br />
                <span>- &nbsp; Karan</span>
              </p>
            </div>

            <div className="_card col-lg-7 m-auto">
              <p className="text-center">
                Nutted Protein has become my favorite place to shop for dry
                fruits and nuts. The products are top-notch, and they always
                arrive in perfect condition. Their commitment to quality and
                timely delivery makes them stand out from the rest.
                <br />
                <br />
                <span>- &nbsp; Jeevitha</span>
              </p>
            </div>
          </OwlCarousel>
        </div>
      </div>

      <div id="contact"></div>
      <div className="contact_us section">
        <div className="container">
          <h2 className="heading white center">Get in Touch</h2>
          <p className="text-center text-white col-lg-10 m-auto">
            Have a question or feedback? We're here to help! Reach out to us
            today and let us assist you in your journey towards healthier
            snacking choices.
          </p>
          {enquiryDone ? (
            <h2 className="text-center text-white mt-5">
              Thanks For Your Enquiry.
              <br></br>
              <br></br>
              Our Team Will Contact You Soon
            </h2>
          ) : (
            <Enquiry setenquiry={setEnquiryDone} />
          )}
        </div>
      </div>
    </div>

  );
}
