import React, { useEffect } from 'react';

export default function RefundPolicy() {

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top of the page when component mounts
      }, []);




  return (
    <div className='refund_policy policy'>
      <div className="container py-5">
        <h2><strong>Refund Policy</strong></h2>

        <p>Welcome to Nutted Protein!</p>

        <p>This Refund Policy outlines the rules and regulations for refunds of products purchased through Nutted Protein's Website, located at https://nuttedprotein.com/</p>

        <p>By making a purchase on this website, you agree to adhere to the terms and conditions outlined in this Refund Policy. If you do not agree with any part of this policy, please refrain from making a purchase.</p>

        <h3><strong>Refund Eligibility</strong></h3>

        <p>We want you to be completely satisfied with your purchase. If for any reason you are not satisfied, you may be eligible for a refund under the following conditions:</p>

        <ul>
          <li>The item(s) must be returned within 3 working days of the purchase date.</li>
          <li>The item(s) must be unused, unopened, and in the same condition as received.</li>
          <li>The item(s) must be in their original packaging.</li>
        </ul>

        <h3><strong>Refund Process</strong></h3>

        <p>To request a refund, please contact our customer service team at +91 63824 46862. Please provide your order number and reason for the refund request.</p>

        <p>Once your request is received and approved, we will initiate the refund process. Refunds will be issued to the original method of payment within 7 business days.</p>

        <h3><strong>Non-Refundable Items</strong></h3>

        <p>Certain items are not eligible for refunds, including:</p>

        <ul>
          <li>Gift cards</li>
          <li>Downloadable software products</li>
          <li>Perishable goods such as food items</li>
        </ul>

        <h3><strong>Shipping Costs</strong></h3>

        <p>Shipping costs are non-refundable. If you receive a refund, the cost of return shipping will be deducted from your refund.</p>

        <h3><strong>Changes to This Policy</strong></h3>

        <p>We reserve the right to update or change this Refund Policy at any time without prior notice. Changes will be effective immediately upon posting on this website. We encourage you to review this Refund Policy periodically for any updates or changes.</p>

        <p>If you have any questions or concerns about our Refund Policy, please contact us at +91 63824 46862.</p>

      </div>
    </div>
  );
}
