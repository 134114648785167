import React, { useEffect, useState } from 'react';
import ProductsData from '../ProductsData';
import AOS from 'aos';
import 'aos/dist/aos.css';

export default function Products({ addCart, cartItems, isHomepage }) {
  // State to keep track of selected mass for each product
  const [selectedMasses, setSelectedMasses] = useState(Array.from({ length: ProductsData.length }, () => 0));
  const [alreadyAdded, setAlreadyAdded] = useState(false);

  // Function to handle changes in selected mass for a specific product
  const selectMass = (index, e) => {
    const newSelectedMasses = [...selectedMasses];
    newSelectedMasses[index] = parseInt(e.target.value);
    setSelectedMasses(newSelectedMasses);
    console.log(newSelectedMasses)
  };

  // Function to handle adding item to cart
  const handleAddToCart = (item, index, e) => {
    const selectedMass = selectedMasses[index];
    console.log('Selected Mass:', selectedMass);
    console.log('Item:', item);
    console.log('Item Product Price:', item.productPrice);
    console.log('Selected Masses:', selectedMasses);
    if (selectedMass === undefined || item.productPrice[selectedMass] === undefined) {
      console.error("Selected mass or product price is undefined.");
      return; // Exit the function early to avoid errors
    }

    const price = item.productPrice[selectedMass][Object.keys(item.productPrice[selectedMass])[0]];
    const quantity = 1;

    const isAlreadyAdded = cartItems && cartItems.some((cartItem) => cartItem.productName === item.productName && cartItem.selectedMass === selectedMass);

    if (isAlreadyAdded) {
      console.log("already added");
      setAlreadyAdded(true);
      setTimeout(() => {
        setAlreadyAdded(false);
      }, 1000); // Set isAlreadyAdded to false after 2 seconds
    } else {
      addCart(item, selectedMass, quantity, price);

      const button = e.target; // Get the clicked button
      console.log(button);
      const productDiv = button.closest('.product'); // Find the closest parent div with class 'product'
      const productImage = productDiv.querySelector('img'); // Find the img tag within the product div

      // Check if product image is found
      if (productImage) {
        // Create a new image element
        const duplicateImage = document.createElement('img');
        duplicateImage.src = productImage.src; // Set the source of the duplicate image to match the original image
        duplicateImage.classList.add("active"); // Add class to apply CSS styles

        // Append the duplicate image to the product div
        productDiv.appendChild(duplicateImage);
      } else {
        console.log('Product image not found');
      }
    }
  };

  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);


  return (
    <>
      {alreadyAdded ? <p className="already_added">Already added to cart</p> : ""}

      <div className="products mt-5">
        {isHomepage ?
          ProductsData.slice(0, 8).map((item, index) => (
            <div className="product" data-aos="zoom-in"   key={index}>
              <img src={item.productImage} alt={item.productName} />
              <h3>{item.productName}</h3>
              <select onChange={(e) => selectMass(index, e)}>
                {item.productPrice.map((priceOption, index) => (
                  <option key={index} value={index}>
                    {Object.keys(priceOption)[0]}g
                  </option>
                ))}
              </select>

              {/* Display price based on selected mass for the specific product */}
              <h6>
                ₹{item.productPrice[selectedMasses[index]][Object.keys(item.productPrice[selectedMasses[index]])[0]]}{' '}
                <s>
                  ₹{item.productPrice[selectedMasses[index]][Object.keys(item.productPrice[selectedMasses[index]])[0]] +
                  (item.productPrice[selectedMasses[index]][Object.keys(item.productPrice[selectedMasses[index]])[0]] * 0.4)}
                </s>
              </h6>

              {/* Pass selected mass and quantity when adding item to cart */}
              <button onClick={(e) => handleAddToCart(item, index, e)}>Add to Cart</button>
            </div>
          )) :
          ProductsData.map((item, index) => (
            <div className="product" data-aos="zoom-in" key={index}>
              <img src={item.productImage} alt={item.productName} />
              <h3>{item.productName}</h3>
              <select onChange={(e) => selectMass(index, e)}>
                {item.productPrice.map((priceOption, index) => (
                  <option key={index} value={index}>
                    {Object.keys(priceOption)[0]}g
                  </option>
                ))}
              </select>
              {/* Display price based on selected mass for the specific product */}
              <h6>
                ₹{item.productPrice[selectedMasses[index]][Object.keys(item.productPrice[selectedMasses[index]])[0]]}{' '}
                <s>
                  ₹{item.productPrice[selectedMasses[index]][Object.keys(item.productPrice[selectedMasses[index]])[0]] +
                  (item.productPrice[selectedMasses[index]][Object.keys(item.productPrice[selectedMasses[index]])[0]] * 0.4)}
                </s>
              </h6>

              {/* Pass selected mass and quantity when adding item to cart */}
              <button onClick={(e) => handleAddToCart(item, index, e)}>Add to Cart</button>
            </div>
          ))
        }
      </div>
    </>
  );
}
