import React, { useState } from "react";
import { Link } from "react-router-dom";

export default function Header({ nav, setnav }) {
  const [show, setShow] = useState(false);

  const handleLinkClick = () => {
    setShow(false); // Collapse the navbar when a link is clicked
  };

  return (
    <>
      <header>
        <nav className={`navbar navbar-expand-lg ${show ? "show" : ""}`}>
          <div className="container">
            <Link className="navbar-brand" to="/">
              <img src={require("./Assets/Logo.webp")} alt="Logo" />
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              onClick={() => setShow(!show)}
              aria-controls="navbarNavAltMarkup"
              aria-expanded={show ? "true" : "false"}
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className={`collapse navbar-collapse ${show ? "show" : ""}`}
              id="navbarNavAltMarkup"
            >
              <div className="navbar-nav">
                <Link
                  className={`nav-link ${nav === "home" ? "active" : ""}`}
                  aria-current="page"
                  to="/"
                  onClick={handleLinkClick} // Add onClick event to handle link click
                >
                  Home
                </Link>
                <Link
                  className={`nav-link ${nav === "about" ? "active" : ""}`}
                  to="/about"
                  onClick={handleLinkClick} // Add onClick event to handle link click
                >
                  About
                </Link>
                <Link
                  className={`nav-link ${nav === "product" ? "active" : ""}`}
                  to="/products"
                  onClick={handleLinkClick} // Add onClick event to handle link click
                >
                  Products
                </Link>
                <Link
                  className={`nav-link ${nav === "contact" ? "active" : ""}`}
                  to="/contact"
                  onClick={handleLinkClick} // Add onClick event to handle link click
                >
                  Contact Us
                </Link>
              </div>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
}
