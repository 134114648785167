import React, { useEffect, useRef, useState } from "react";
import "../App.css";
import axios from "axios";
import emailjs from '@emailjs/browser';

export default function PlaceOrder({ cartItems }) {

  const [coupon, setCoupon] = useState("");
  const [couponMsg, setCouponMsg] = useState(false);
  const [couponValid, setCouponValid] = useState(false);

  useEffect(() => {
    if (cartItems.length === 0) {
      window.location.href = "/";
      console.log("Redirecting to homepage because cart is empty");
    }
  }, [cartItems]);

  const form = useRef();

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page when component mounts
  }, []);

  const [orderValue, setOrderValue] = useState(0);
  const [deliveryCharge, setDeliveryCharge] = useState(60);

  const applyCoupon = () => {
    if (coupon === "DEL60" && orderValue >= 2000) {
      setCouponMsg('Congrats! Your amount has been reduced by applying the coupon code.');
      setCouponValid(true);
    } else if (coupon === "") {
      setCouponMsg("Please Enter Coupon Code");
      setCouponValid(false);
    } else if (coupon === "DEL60npm" && orderValue < 2000) {
      setCouponMsg("Sorry, This coupon is only applicable for orders above ₹2000");
      setCouponValid(false);
    } else {
      setCouponMsg('Invalid Coupon');
      setCouponValid(false);
    }
  };

  useEffect(() => {
    // Calculate total price when cartItems change
    const totalPrice = cartItems.reduce(
      (total, item) => total + item.price * item.quantity,
      0
    );
    setOrderValue(totalPrice);

    if (totalPrice > 2000) {
      setDeliveryCharge(0);
    } else {
      setDeliveryCharge(60);
    }
    // console.log(cartItems);
  }, [cartItems]);

  const [name, setName] = useState("");
  const [number, setNumber] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [door, setDoor] = useState("");
  const [street, setStreet] = useState("");
  const [area, setArea] = useState("");

  const [errors, setErrors] = useState({});

  const validateForm = () => {
    let errors = {};
    let isValid = true;

    if (!name.trim()) {
      errors.name = "Name is required";
      isValid = false;
    }

    if (!number.trim()) {
      errors.number = "Contact Number is required";
      isValid = false;
    }

    if (!email.trim()) {
      errors.email = "Email is required";
      isValid = false;
    }

    if (!street.trim()) {
      errors.street = "Street is required";
      isValid = false;
    }

    if (!door.trim()) {
      errors.door = "Door No. is required";
      isValid = false;
    }

    if (!area.trim()) {
      errors.area = "Area/Locality is required";
      isValid = false;
    }

    setErrors(errors);
    return isValid;
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
    setErrors((prevErrors) => ({ ...prevErrors, name: "" }));
  };

  const handleNumberChange = (e) => {
    const inputValue = e.target.value;
    if (/^\d{0,10}$/.test(inputValue)) {
      setNumber(inputValue);
      setErrors((prevErrors) => ({ ...prevErrors, number: "" }));
    }
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setErrors((prevErrors) => ({ ...prevErrors, email: "" }));
  };

  const handleDoorChange = (e) => {
    setDoor(e.target.value);
    setErrors((prevErrors) => ({ ...prevErrors, door: "" }));
  };

  const handleStreetChange = (e) => {
    setStreet(e.target.value);
    setErrors((prevErrors) => ({ ...prevErrors, street: "" }));
  };

  const handleAreaChange = (e) => {
    setArea(e.target.value);
    setErrors((prevErrors) => ({ ...prevErrors, area: "" }));
  };

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
    setErrors((prevErrors) => ({ ...prevErrors, message: "" }));
  };

  const SubmitEnquiry = (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    let productDetails = "";

    cartItems.forEach((item, index) => {
      productDetails += `${item.productName} (${
        item.selectedMass === 0 ? "250g" : "500g"
      }) - ${item.quantity} Nos.`;
      if (index !== cartItems.length - 1) {
        productDetails += ",\n"; // Include line break after comma
      }
    });

    const options = {
      key: "rzp_live_HXsGI38kvKkZP3",
      key_secret: "uRBHGNJrlzLDTTUKKqHIG7fq",
      amount:
        (coupon === "DEL60" && orderValue >= 2000 ? orderValue - 100 : orderValue) * 100 + deliveryCharge * 100,
      currency: "INR",
      name: "Nutted Protein",
      description: "Where health meets flavor",
      handler: function (response) {
        if (response.razorpay_payment_id) {
          console.log("Payment successful. Payment ID:", response.razorpay_payment_id);
          
          // Redirect to thank you page with email parameter
          window.location.href = `/thankyou?email=${email}`;
        } else {
          console.log("Payment denied or failed.");
          window.location.href = "/paymentcancelled";
        }
        
      },
      prefill: {
        name: "Rithish Abinav",
        email: email,
        contact: number,
      },
      notes: {
        address: `${door}, ${street}, ${area}`,
        message: `${message}`
      },
      theme: {
        color: "#271C1C",
      },
    };

    var pay = new window.Razorpay(options);
    pay.open();

    axios
      .post(
        "https://script.google.com/macros/s/AKfycbxqxIHQK6J_aTnlz7Lz_Qyy0iPA88CED2QsgtUDELGs8QFqUht45ede62sS5aJOwPrQ/exec",
        new URLSearchParams({
          Name: name,
          Number: number,
          Email: email,
          Door: door,
          Street: street,
          Area: area,
          Message: message,
          productDetail: productDetails,
        })
      )
      .then((res) => {
        // console.log(res);
        // Clear form fields on successful submission
        setName("");
        setMessage("");
        setNumber("");
        setEmail("");
        setDoor("");
        setStreet("");
        setArea("");
        setErrors({});
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <div className="place_order section">
      <div className="container">
        <div className="form" onSubmit={(e) => SubmitEnquiry(e)}>
          <p>Please enter the details to place order</p>
          <form ref={form}>
            <input
              type="text"
              placeholder="Your Name" name="sender_name"
              value={name}
              onChange={handleNameChange}
            />
            {errors.name && <div className="error-text">{errors.name}</div>}

            <input
              type="text"
              placeholder="Your Number"
              value={number}
              onChange={handleNumberChange}
            />
            {errors.number && <div className="error-text">{errors.number}</div>}

            <input
              id="email"
              type="text"
              placeholder="Your Mail ID" name="sender_mail"
              value={email}
              onChange={handleEmailChange}
            />
            {errors.email && <div className="error-text">{errors.email}</div>}

            <input
              type="text"
              placeholder="Door No."
              value={door}
              onChange={handleDoorChange}
            />
            {errors.door && <div className="error-text">{errors.door}</div>}

            <input
              type="text"
              placeholder="Street Name"
              value={street}
              onChange={handleStreetChange}
            />
            {errors.street && <div className="error-text">{errors.street}</div>}

            <input
              type="text"
              placeholder="Area/Locality"
              value={area}
              onChange={handleAreaChange}
            />
            {errors.area && <div className="error-text">{errors.area}</div>}

            <textarea
              placeholder="Type message here..."
              value={message}
              onChange={handleMessageChange}
            />
            <button type="submit" className="gold_btn">
              Proceed to Order ₹{orderValue + deliveryCharge}
            </button>
          </form>
        </div>

        <div className="table">
          <table>
            <thead>
              <tr>
                <th>Product</th>
                <th>Quantity</th>
                <th>Price</th>
              </tr>
            </thead>
            <tbody>
              {cartItems.map((item, index) => (
                <tr key={index}>
                  <td>
                    {item.productName}-
                    {item.selectedMass === 0 ? "250g" : "500g"}
                  </td>
                  <td>{item.quantity} Nos.</td>
                  <td>{item.price * item.quantity}</td>
                </tr>
              ))}
              <tr>
                <td colSpan="2">Total Price</td>
                <td>₹{orderValue}</td>
              </tr>
              <tr>
                <td colSpan="2">
                  Delivery Fee<sub> (Free delivery for orders above ₹1000)</sub>
                </td>
                <td>₹{deliveryCharge}</td>
              </tr>
              <tr>
                <td colSpan="2">
                  <input placeholder="Enter Coupon Code" value={coupon} onChange={(e) => setCoupon(e.target.value)} type="text" />
                </td>
                <td><button onClick={applyCoupon} className="gold_btn">Apply</button></td>
              </tr>
              {couponMsg && (
                <tr>
                  <td colSpan="3" className={couponValid ? "coupon_congrats" : "error_coupon"}>
                    {couponMsg}
                  </td>
                </tr>
              )}

              <tr>
                <td colSpan="2">Amount Payable</td>
                {coupon === 'DEL60' && orderValue >= 2000 ? (
                  couponMsg === "Invalid Coupon" ? (
                    <td>₹{orderValue + deliveryCharge}</td>
                  ) : (
                    <td>₹<s>{orderValue + deliveryCharge}</s> {orderValue - 100}</td>
                  )
                ) : (
                  <td>₹{orderValue + deliveryCharge}</td>
                )}
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
