import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import './App.css';
import './mobile.css';
import Header from './Header';
import Products from './pages/Products';
import Contact from './pages/Contact';
import Home from './pages/Home';
import About from './pages/About';
import Footer from './Footer';
import Cart from './pages/Cart';
import ProductPage from './pages/ProductPage';
import PlaceOrder from './pages/PlaceOrder';
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS CSS
import TermsCondition from './pages/TermsCondition';
import PrivacyPolicy from './pages/PrivacyPolicy';
import ShippingPolicy from './pages/ShippingPolicy';
import RefundPolicy from './pages/RefundPolicy';

import PayDone from './pages/PayDone';
import PayCancel from './pages/PayCancel';

export default function App() {
  const [isCartActive, setIsCartActive] = useState(false);
  const [cartItems, setCartItems] = useState([]);

  const handleCartClick = () => {
    setIsCartActive(!isCartActive);
  };

  const addCart = (product, selectedMass, quantity, price) => {
    const updatedCartItems = [...cartItems, { ...product, selectedMass, quantity, price }];
    setCartItems(updatedCartItems);
  };

  useEffect(() => {
    // Initialize AOS when the component mounts
    AOS.init({
      // Global settings:
      disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
      startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
      initClassName: 'aos-init', // class applied after initialization
      animatedClassName: 'aos-animate', // class applied on animation
      useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
      disableMutationObserver: false, // disables automatic mutations' detections (advanced)
      debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
      throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)
      
      // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
      offset: 120, // offset (in px) from the original trigger point
      delay: 0, // values from 0 to 3000, with step 50ms
      duration: 400, // values from 0 to 3000, with step 50ms
      easing: 'ease', // default easing for AOS animations
      once: false, // whether animation should happen only once - while scrolling down
      mirror: false, // whether elements should animate out while scrolling past them
      anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation
    });
  }, []); // Run this effect only once on initial render

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page when component mounts
  }, []);

  const [nav,setNav]=useState("");

  return (
    <BrowserRouter>
      <Header nav={nav} setnav={setNav} />
   
      <Cart onCartClick={handleCartClick} isActive={isCartActive} setiscart={setIsCartActive} cartItems={cartItems} setCartItems={setCartItems} />
      <Routes>
        <Route path="/" element={<Home setnav={setNav} addCart={addCart} cartItems={cartItems} />} />
        <Route path="/about" element={<About setnav={setNav} />} />
        <Route path="/products" element={<ProductPage setnav={setNav} addCart={addCart} cartItems={cartItems} />} />
        <Route path="/contact" element={<Contact setnav={setNav} />} />
        <Route path="/order" element={<PlaceOrder cartItems={cartItems}  />} />
        <Route path="/termsandconditions" element={<TermsCondition  />} />
        <Route path="/privacypolicy" element={<PrivacyPolicy  />} />
        <Route path="/shippingpolicy" element={<ShippingPolicy  />} />
        <Route path="/refundpolicy" element={<RefundPolicy  />} />
        <Route path="/thankyou" element={<PayDone  />} />
        <Route path="/paymentcancelled" element={<PayCancel  />} />



      </Routes>
      <Footer />
    </BrowserRouter>
  );
}


