const ProductsData = [

    {
        productName: "Cashew Full",
        productImage:require('./Assets/product_img/cashew.webp'),
        productPrice: [{ '500': 370 }, { "250": 210 }],
    },
    {
        productName: "Cashew Broken",
        productImage:require('./Assets/product_img/cashew_broken.webp'),
        productPrice: [{ '500': 360 }, { "250": 200 }],
    },
    {
        productName: "Cashew 4 Piece",
        productImage:require('./Assets/product_img/cashew_peice.webp'),
        productPrice: [{ '500': 290 }, { "250": 160 }],
    },
    {
        productName: "Badam",
        productImage:require('./Assets/product_img/almond.webp'),
        productPrice: [{ '500': 350 }, { "250": 190 }],
    },
    {
        productName: "Salt Pista",
        productImage:require('./Assets/product_img/salt_pista.webp'),
        productPrice: [{ '500': 540 }, { "250": 280 }],
    },
    {
        productName: "Green Pista",
        productImage:require('./Assets/product_img//green_pista.webp'),
        productPrice: [{ '500': 1120 }, { "250": 570 }],
    },
    {
        productName: "Kishmish",
        productImage:require('./Assets/product_img/kismish.webp'),
        productPrice: [{ '500': 170 }, { "250": 100 }],
    },
    {
        productName: "Black Grapes (Seed)",
        productImage:require('./Assets/product_img/black_grapes.webp'),
        productPrice: [{ '500': 190 }, { "250": 110 }],
    },
    {
        productName: "Black Grapes (Seedless)",
        productImage:require('./Assets/product_img/black_grapes.webp'),
        productPrice: [{ '500': 210 }, { "250": 120 }],
    },
    {
        productName: "Black Dates",
        productImage:require('./Assets/product_img/black_dates.webp'),
        productPrice: [{ '1000': 300 }, { '500': 160 }],
    },
    {
        productName: "Black Khajurka (Dry Dates)",
        productImage:require('./Assets/product_img/black_kajurkha.webp'),
        productPrice: [{ '1000': 380 }, { '500': 200 }],
    },
    {
        productName: "Yellow Khajurka (Dry Dates)",
        productImage:require('./Assets/product_img/yellow_kajurkha.webp'),
        productPrice: [{ '1000': 400 }, { '500': 210 }],
    },
    {
        productName: "Fig",
        productImage:require('./Assets/product_img/black_kajurkha.webp'),
        productPrice: [{ '500': 500 }, { "250": 270 }],
    },
    {
        productName: "Walnut Broken",
        productImage:require('./Assets/product_img/walnut_broken.webp'),
        productPrice: [{ '500': 500 }, { "250": 270 }],
    },
    {
        productName: "Walnut Shelled",
        productImage:require('./Assets/product_img/walnut_shelled.webp'),
        productPrice: [{ '500': 520 }, { "250": 270 }],
    },
    {
        productName: "Mixed Dry Fruit",
        productImage:require('./Assets/product_img/mixed_dry_fruit.webp'),
        productPrice: [{ '750': 480 }, { '500': 340 }],
    },
    {
        productName: "Dried Mango",
        productImage:require('./Assets/product_img/dried_mango.webp'),
        productPrice: [{ '1000': 660 }, { "200": 170 }],
    },
    {
        productName: "Afhanistan Grapes",
        productImage:require('./Assets/product_img/afghanistan_grapes.webp'),
        productPrice: [{ '1000': 380 }, { "250": 160 }],
    },
    {
        productName: "Mamra Almond",
        productImage:require('./Assets/product_img/mamra_almond.webp'),
        productPrice: [{ '500': 1500 }, { "250": 770 }],
    },
    {
        productName: "Badam Pisini",
        productImage:require('./Assets/product_img/badam_pisini.webp'),
        productPrice: [{ '500': 190 }, { "250": 110 }],
    },
    {
        productName: "Apricot",
        productImage:require('./Assets/product_img/apricot.webp'),
        productPrice: [{ '1000': 1100 }, { "200": 220 }],
    },
    {
        productName: "Sweet Amla",
        productImage:require('./Assets/product_img/sweet_amla.webp'),
        productPrice: [{ '500': 190 }, { "250": 110 }],
    },
    {
        productName: "Sara Parappu",
        productImage:require('./Assets/product_img/sara_paruppu.webp'),
        productPrice: [{ '500': 860 }, { "250": 440 }],
    },
    {
        productName: "Makhana",
        productImage:require('./Assets/product_img/makhana.webp'),
        productPrice: [{ '500': 340 }, { "250": 190 }],
    },
    {
        productName: "Dried Cranberry",
        productImage:require('./Assets/product_img/dried_cranberry.webp'),
        productPrice: [{ '1000': 690 }, { "200": 170 }],
    },
    {
        productName: "Dried Strawberry",
        productImage:require('./Assets/product_img/dried_strawberry.webp'),
        productPrice: [{ '1000': 190 }, { "200": 220 }],
    },
    {
        productName: "Dried Blueberry",
        productImage:require('./Assets/product_img/dried_blueberry.webp'),
        productPrice: [{ '1000': 1650 }, { "200": 330 }],
    },
    {
        productName: "Dried Pineapple",
        productImage:require('./Assets/product_img/dried_pineapple.webp'),
        productPrice: [{ '1000': 500 }, { "200": 130 }],
    },
    {
        productName: "Sweet Ginger",
        productImage:require('./Assets/product_img/sweet_ginger.webp'),
        productPrice: [{ '1000': 420 }, { "200": 120 }],
    },
    {
        productName: "Cherry",
        productImage:require('./Assets/product_img/cherry.webp'),
        productPrice: [{ '1000': 270 }, { '500': 150 }],
    },
    {
        productName: "Kiwi",
        productImage:require('./Assets/product_img/kiwi.webp'),
        productPrice: [{ '500': 280 }, { "250": 160 }],
    },
    {
        productName: "Chia Seeds",
        productImage:require('./Assets/product_img/chia_seed.webp'),
        productPrice: [{ '500': 240 }, { "250": 140 }],
    },
    {
        productName: "Pumpkin Seed",
        productImage:require('./Assets/product_img/pumpkin_seed.webp'),
        productPrice: [{ '500': 320 }, { "250": 180 }],
    },
    {
        productName: "Sabja Seed",
        productImage:require('./Assets/product_img/sabja_seed.webp'),
        productPrice: [{ '500': 220 }, { "250": 230 }],
    },
    {
        productName: "Flax Seed",
        productImage:require('./Assets/product_img/flax_seed.webp'),
        productPrice: [{ '1000': 250 }, { '500': 140 }],
    },
    {
        productName: "Cucumber Seed",
        productImage:require('./Assets/product_img/almond.webp'),
        productPrice: [{ '500': 560 }, { "250": 300 }],
    },

]

export default ProductsData;