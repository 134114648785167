import React, { useEffect, useState } from 'react';
import ProductsData from '../ProductsData'
import Products from './Products';

export default function ProductPage({setnav, addCart ,cartItems}) {


  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page when component mounts
    setnav("product")
  }, []);



  return (
    <div className='product_page'>


      <div className="banner">
        <div className="container">

          <div className="image" data-aos="fade-right">
<img alt="Nutted Protein" className='d-none d-sm-none d-md-block d-lg-block' src={require('../Assets/products.webp')}/>
<img alt="Nutted Protein" className='d-block d-sm-block d-md-none d-lg-none' src={require('../Assets/product_mobile.webp')}/>

</div>

<div className="content" data-aos="fade-left">
  <h1>Nutted Protein's Nutty Bliss!</h1>
  <p>Where every bite is a step towards health and satisfaction.<br></br>Elevate your snacking game with our premium dry fruits and nuts today!</p>
  <a href='#products' className="gold_btn text-decoration-none">Shop Now</a>
</div>

</div>
      </div>

<div id="products"></div>
      <div className="section">
        <div className="container">
      <h1 className='heading center'>
        Our Premium Products
      </h1>
      <p className='text-center'>Indulge in our exquisite collection of premium dry fruits and nuts, handpicked for superior freshness and taste. Elevate your snacking experience with our high-quality products, available at affordable prices.</p>
   
      <Products addCart={addCart} cartItems={cartItems}/>
   
      </div>
    </div>
    </div>
  )
}
