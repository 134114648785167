import React, { useEffect } from 'react'

export default function About({setnav}) {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page when component mounts
    setnav("about")
  }, []);
  return (
    <div className='aboutpage'>
      <div className="banner">
        <div className="container">
          <h1 className='mb-0'>About Nutted Protein </h1>
          <p className='text-center p-4'>At Nutted Protein, we believe that everyone deserves access to high-quality dry fruits and nuts, regardless of their budget. Our journey began on December 1, 2023, with a simple yet powerful mission: to make nutritious snacks affordable and accessible to all.</p>
        </div>
      </div>


<div className="mission_afford section">
  <div className="container">
    <div className="image" data-aos="fade-right">
      <img alt="Nutted Protein" src={require('../Assets/products.webp')}/>
    </div>
    <div className="content" data-aos="fade-left">
      <h3 className='heading'> Our Mission, Quality and Affordability </h3>
      <p>Our mission is to provide premium-quality dry fruits and nuts at the best prices, ensuring that everyone can enjoy the health benefits of these wholesome snacks. We understand the importance of maintaining a balanced diet, and we're committed to helping our customers achieve their health and wellness goals by offering a wide range of nutritious options.</p>
    <p>We take great pride in the quality of our products. Each item in our selection is carefully sourced and rigorously tested to meet our high standards. From succulent dates to crunchy almonds, every Nutted Protein product is bursting with flavor and packed with essential nutrients.

Despite our unwavering commitment to quality, we remain dedicated to keeping our prices affordable. By cutting out the middleman and streamlining our operations, we're able to pass on significant savings to our customers without compromising on quality.</p>
    </div>
  </div>
</div>

<div className="section who" data-aos="fade-left">
  <div className="container">
    <h3 className='heading center'>Who We Are</h3>
    <p className='text-center'>At Nutted Protein, we're more than just a business. We're a community of like-minded individuals who are passionate about health, wellness, and delicious food. Our team is comprised of dedicated professionals who share a common goal: to make a positive impact on people's lives by providing access to wholesome and nutritious snacks.

Since our inception on December 1, 2023, we've been driven by a shared vision of promoting a healthier lifestyle through our products. We believe that food should not only nourish the body but also delight the taste buds. That's why we carefully curate each item in our selection, ensuring that it meets our stringent standards for quality, taste, and nutritional value.</p>
  </div>
</div>

<div className=" section  what" data-aos="fade-right">
  <div className="container">
    <h3 className='heading center'>What We Are</h3>
    <p className='text-center'>Nutted Protein is more than just a store – it's a destination for health-conscious individuals seeking high-quality dry fruits and nuts at unbeatable prices. Our extensive range of products caters to a diverse range of tastes and preferences, making it easy for everyone to find something they love.

Whether you're looking for a quick and convenient snack on the go or ingredients to elevate your favorite recipes, Nutted Protein has you covered. From succulent dates and crunchy almonds to creamy cashews and more, we offer a wide variety of options to suit every palate.

With over 300 satisfied customers and counting, we take pride in our reputation for excellence. Our commitment to quality, affordability, and customer satisfaction sets us apart in the market, and we're honored to be your trusted source for premium-quality dry fruits and nuts. Join us on our journey to better health and wellness – one delicious bite at a time.</p>
  </div>
</div>

<div className=" section  community" data-aos="fade-left">
  <div className="container">
    <h3 className='heading center'>Our Community</h3>
    <p className='text-center'>Since our inception, we've had the pleasure of serving over 300 satisfied customers and counting. Their satisfaction is our greatest reward, and we strive to exceed their expectations with every purchase. Whether you're a health-conscious individual, a fitness enthusiast, or simply someone who appreciates delicious snacks, we're here to cater to your needs.</p>
  </div>
</div>

<div className=" section join" data-aos="fade-right">
  <div className="container">
    <h3 className='heading center'>Join Us</h3>
    <p className='text-center'>We invite you to join the Nutted Protein community and experience the difference for yourself. Whether you're stocking up on pantry essentials or searching for the perfect gift, you'll find everything you need and more at Nutted Protein. Thank you for choosing us as your trusted source for premium-quality dry fruits and nuts.</p>
  </div>
</div>


    </div>
  )
}
