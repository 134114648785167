import React from 'react'

export default function PayCancel() {
  return (
    <div className='thankyou section'>
    <div className="container">
        <h1>Payment Cancelled</h1>
        <p>We're sorry, but your payment was canceled. Please try again later.</p>
    </div>
</div>
  )
}
