import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom'; // Import useLocation hook
import emailjs from '@emailjs/browser';

export default function PayDone() {
  const location = useLocation(); // Use useLocation hook to get URL parameters
  const email = new URLSearchParams(location.search).get('email'); // Get email parameter

  useEffect(() => {
    if (email) {
      emailjs
        .send('service_gzq5sjv', 'template_5or2r9h', { sender_mail: email }, 'fGEDN3ejG_kaOOXvx')
        .then(
          () => {
            console.log('Email sent successfully!');
          },
          (error) => {
            console.log('Email sending failed:', error.text);
          },
        );
    }
  }, []);

  return (
    <div className='thankyou section'>
      <div className="container">
        <h1>Thank you for placing your order with Nutted Protein</h1>
        <p>Please check your email ({email}) for confirmation details. Rest assured, we're committed to delivering your order promptly within 24 to 72 hours. We appreciate your trust and look forward to serving you!</p>
      </div>
    </div>
  );
}
