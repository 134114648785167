import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {useLocation } from 'react-router-dom';



export default function Cart({ onCartClick, isActive,setiscart, cartItems, setCartItems }) {
  

  const location = useLocation();

  useEffect(() => {
    setiscart(false); 
  }, [location.pathname]); 


  const [total, setTotal] = useState(0);

  useEffect(() => {
    console.log(cartItems)
    let totalPrice = 0;
    cartItems.forEach((item) => {
      totalPrice += item.price * item.quantity;
    });
    setTotal(totalPrice);
  }, [cartItems]);

  const decrement = (index) => {
    const updatedCartItems = [...cartItems];
    
    if (updatedCartItems[index].quantity > 1) {
      updatedCartItems[index].quantity--;
      setCartItems(updatedCartItems);
    } else {
      updatedCartItems.splice(index, 1);
      setCartItems(updatedCartItems);
      console.log(cartItems)

    }
  };
  
  const increment = (index) => {
    const updatedCartItems = [...cartItems];
    updatedCartItems[index].quantity++;
    setCartItems(updatedCartItems);
    console.log(cartItems)
  };

  return (
    <div className={`cart ${isActive ? 'active' : ''}`}>
      {isActive ?
        <div onClick={onCartClick} className="cart_icon">Close <i className="fa-solid fa-xmark"></i></div> :
        <div onClick={onCartClick} className="cart_icon">
          Cart<i className="fa-solid fa-cart-shopping"></i>{cartItems && cartItems.length > 0 && <span>{cartItems.length}</span>}
        </div>
      }
       {cartItems && cartItems.length > 0 ? <p className="above_1000"><i class="fa-solid fa-truck" ></i> Order above ₹2000 & <span>Get Free Delivery</span> </p> :""}   <h2>Your Cart</h2>
      {cartItems && cartItems.length > 0 ? "" :
      <div className="empty_cart">
        <img alt="Nutted Protein" src={require('../Assets/empty_cart_icon.webp')}/>
        <p>Your cart is Empty.</p>
      </div>
}
    
      <div className='allcarts'>
        {cartItems.map((item, index) => (
          <div className='each_cart' key={index}>
            <img  className='product_img' src={item.productImage} alt={item.productName} />
            <div className="detail">
              <p className='productname'>{item.productName} </p>
              {/* <p className='productquantity'>{item.selectedMass === 0 ? "1Kg" : item.selectedMass === 1 ? "500g" : "250g"}</p> */}
              {/* <p className='productquantity'>{item.selectedMass === 0 ? "1Kg" : item.selectedMass === 1 ? "500g" : "250g"}</p> */}
              <p className='productquantity'>
  {item.selectedMass === 0 && item.productPrice.find(obj => obj.hasOwnProperty(1000)) ? "1Kg" :
              item.selectedMass === 0 && item.productPrice.find(obj => obj.hasOwnProperty(750)) ? "750g" :
    item.selectedMass === 0 && item.productPrice.find(obj => obj.hasOwnProperty(500)) ? "500g" :
      item.selectedMass === 1 && item.productPrice.find(obj => obj.hasOwnProperty(250)) ? "250g" :
        item.selectedMass === 1 && item.productPrice.find(obj => obj.hasOwnProperty(200)) ? "200g" :
          item.selectedMass === 1 && item.productPrice.find(obj => obj.hasOwnProperty(500)) ? "500g" :
            item.selectedMass === 0 && item.productPrice.find(obj => obj.hasOwnProperty(750)) ? "750g" :
              "Unknown"
  }
</p>




              <p className='productprice'>₹{item.price}</p>
            </div>
            <div className="selectQuantity">
              <button onClick={() => decrement(index)}>-</button>
              <input
                type='number'
                value={item.quantity}
                readOnly
              />
              <button  onClick={() => increment(index)}>+</button>
            </div>
          </div>
        ))}
      </div>
      {cartItems && cartItems.length > 0 ? 
            <Link to='/order' className='place_order_btn'>Place Order</Link>
             :""
      }

      {cartItems && cartItems.length > 0 &&
        <div className="total_price">
          <h1>Total Price is ₹{total}</h1>
        </div>
      }
    </div>
  );
}
